@use "@angular/material" as mat;

/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

@import "./global.scss";

body,
html {
  * {
    font-size: 16px;
  }

  background-color: #f6f7fb;
  color: #41434b;
  font-family: "Poppins", sans-serif; 
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 10;
}

.btn {
  position: relative; // For spinner to show in the correct position
}

svg {
  pointer-events: none;
}

.dense-1 {
  @include mat.all-component-densities(-1);
}

mat-form-field {
  @include mat.all-component-densities(-2);
}

