@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:wght@300;400;500;600&display=swap");
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "./variables.scss";

body,
html {
  * {
    font-size: 16px;
  }

  background-color: #f6f7fb;
  color: #41434b;
  font-family: "Poppins", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

figure {
  margin: 0;
}

// CONTAINERS
.container {
  display: flex;

  &--fixedBottom {
    bottom: 0;
    min-width: 100%;
    position: fixed;
    padding: 0;
  }
}

// TEXTS
.text-centered {
  text-align: center;
}

// LINKS
.pretitle-link {
  color: $primary;
  font-weight: 500;
}

.search-box {
  background: url("/assets/icons/search.svg") left 0.5rem center no-repeat
    $white;
  border-radius: 0.5rem;
  border: thin solid #eaeaea;
  height: 2.5rem;
  padding: 0 0.5rem 0 2.5rem;
  width: 18.75rem;

  &::placeholder {
    color: #b3b4bc;
  }
}

// LOADER
.mooncheckLoader {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  display: block;
  margin: 1.5rem auto 1rem;
  position: relative;
  background: $light-purple;
  box-shadow: -1.5rem 0 $light-purple, 1.5rem 0 $light-purple;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;

  small {
    color: $primary;
    font-size: $text12;
    font-weight: 400;
    min-width: max-content;
    position: absolute;
    top: -1.5rem;
    transform: translateX(-2.75rem);
  }
}

@keyframes shadowPulse {
  33% {
    background: $light-purple;
    box-shadow: -1.5rem 0 $primary, 1.5rem 0 $light-purple;
  }
  66% {
    background: $primary;
    box-shadow: -1.5rem 0 $light-purple, 1.5rem 0 $light-purple;
  }
  100% {
    background: $light-purple;
    box-shadow: -1.5rem 0 $light-purple, 1.5rem 0 $primary;
  }
}

// Blank space separators
.blankSpace {
  display: block;

  &--80px {
    height: 5rem;
  }
}

// PILLS
.pill {
  align-items: center;
  border-radius: 0.625rem;
  display: flex;
  font-size: $text12;
  font-weight: 500;
  min-width: 6.25rem;
  padding: 0.25rem 1rem;
  justify-content: center;

  &--sharp {
    border-radius: 1rem;
    font-weight: 400;
  }

  &--small {
    min-width: 3.75rem;
  }

  &--xSmall {
    border-radius: 1rem;
    font-weight: 600;
    min-width: auto;
  }

  &--verified {
    background-color: #aff4c6;
    color: #098915;
  }

  &--primary {
    background-color: $primary;
    color: $white;
  }

  &--primaryAlt {
    background-color: $primary-alt;
    color: $white;
  }

  &--secondary {
    background-color: #e6daff;
    color: #843cfc;
  }

  &--secondaryAlt {
    background-color: #e7cfff;
    color: #8447e9;
  }

  &--pending {
    background-color: #fff0b9;
    color: #ffa901;
  }

  &--disabled {
    background-color: #e3e8ed;
    color: $text-grey;
  }

  &--ghost {
    background-color: $white;
    color: $primary;
    min-width: 0;
    padding: 0.25rem 0;
  }

  &--green {
    background-color: $green;
    color: $white;
  }

  &--lightGreen {
    background-color: #aff4c6;
    color: #098915;
  }

  &--lightPurple {
    background-color: #e8e8ff;
    color: $primary;
  }

  &--lightOrange {
    background-color: #ffdfaf;
    color: #ff9900;
  }

  &--red {
    background-color: $light-red;
    color: $alert-red;
  }

  img {
    margin-right: 0.1875rem;
  }
}

// ICONS
.facebook-icon {
  background: url("/assets/icons/facebook.svg") center no-repeat;
  background-size: contain;
}

.youtube-icon {
  background: url("/assets/icons/youtube.svg") center no-repeat;
  background-size: contain;
}

.linkedin-icon {
  background: url("/assets/icons/linkedin.svg") center no-repeat;
  background-size: contain;
}

.instagram-icon {
  background: url("/assets/icons/instagram.svg") center no-repeat;
  background-size: contain;
}

.alert-icon {
  background: url("/assets/icons/alert.svg") center no-repeat;
  background-size: contain;
}

// MODALS
.modal-content {
  border-radius: 1.25rem;
  padding: 2.5rem 3.5rem;
}

.modal-header {
  border: 0;
  color: $text-dark;
  font-size: $text24;
  justify-content: center;
  margin-bottom: 2.5rem;
  padding: 0;

  .btn-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
}

.modal-subtitle {
  margin-top: -1.25rem;
  margin-bottom: 1.25rem;

  p {
    font-size: $text14;
    font-weight: 300;
  }
}

.modal-body {
  padding: 0;

  .modalParagraph {
    color: $text-dark;
    font-size: $text14;
    font-weight: 300;

    strong {
      font-size: $text14;
    }
  }

  .modalContainer {
    &__textarea {
      display: flex;
      flex-flow: column;
      gap: 0.25rem;
      margin-top: 1.25rem;

      span {
        font-size: $text12;
      }

      textarea {
        border: thin solid #eaeaea;
        border-radius: 0.5rem;
        font-size: $text12;
        height: 9.125rem;
        overflow-y: scroll;
        padding: 0.625rem 0.5rem;
        resize: none;
      }
    }
  }
}

.modal-footer {
  border: 0;
  margin-top: 2.5rem;
  padding: 0;

  .modal-disclaimer {
    font-size: $text10;
    margin-top: 1.25rem;
  }
}

// FORM ELEMENTS
.form {
  &__element {
    display: flex;
    flex-flow: column;
    gap: 0.25rem;
    margin-bottom: 1.5rem;

    &:last-child {
      margin: 0;
    }

    &--double {
      flex-flow: row;
      gap: 1rem;
    }

    &--row {
      align-items: center;
      flex-flow: row;
      gap: 0.5rem;
    }

    &--child {
      display: flex;
      flex-flow: column;
      gap: 0.25rem;
      max-width: calc(50% - 0.5rem);
    }
  }

  &__label {
    color: $text-dark;
    font-size: $text12;

    &--required {
      color: $alert-red;
      font-size: $text12;
    }
  }

  &__input {
    background-color: $white;
    border-radius: 0.5rem;
    border: thin solid #eaeaea;
    color: $text-dark;
    padding: 0.625rem 0.5rem;

    &::placeholder {
      color: $text-grey;
    }

    &--icon {
      padding-left: 2.5rem;
    }

    &--iconReverse {
      padding-right: 2.5rem;
    }
  }

  &__helper {
    color: $text-grey;
    font-size: $text10;
  }

  .form-check-input {
    border: 0.125rem solid $primary;
    height: 1.5rem;
    margin: 0;
    width: 1.5rem;

    &:checked {
      background-color: $primary;
    }
  }

  &__textarea {
    background-color: $white;
    border-radius: 0.5rem;
    border: thin solid #eaeaea;
    color: $text-dark;
    min-height: 12rem;
    padding: 0.625rem 0.5rem;
    resize: none;

    &::placeholder {
      color: $text-grey;
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    margin-top: 2.5rem;

    &--smTop {
      margin-top: 1rem;
    }
  }

  // FORM ELEMENT ICONS
  .linkIcon {
    background: url("/assets/icons/link-gray.svg") left 0.5rem center no-repeat
      $white;
    background-size: 1.5rem 1.5rem;
  }

  .searchIcon {
    background: url("/assets/icons/search.svg") left 0.5rem center no-repeat
      $white;
    background-size: 1.5rem 1.5rem;
  }

  .dateIcon {
    background: url("/assets/icons/date.svg") right 0.5rem center no-repeat
      $white;
  }
}

// FILE SELECTOR
.fileSelector {
  align-items: center;
  background-color: #eaeaff;
  border-radius: 1rem;
  border: 0.125rem dashed rgba(75, 78, 252, 0.4);
  display: flex;
  flex-flow: column;
  margin: 1rem 0;
  padding: 1.5rem;
  width: 100%;

  &__label,
  &__label span {
    color: $primary;
    font-size: $text14;
    font-weight: 500;

    span {
      color: $text-dark;
    }
  }

  &__helper,
  &__helper span {
    color: $text-grey;
    font-size: $text14;

    span {
      margin-left: 0.5rem;
    }
  }
}

// BUTTONS
.btnsContainer {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin: 0;
  width: 100%;

  button {
    flex: 1;
  }
}

.mooncheckBtn {
  border-radius: 0.625rem;
  border: 0;
  padding: 0.5rem 1.25rem;
  font-size: $text14;
  font-weight: 500;

  &--icon {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    padding-left: 0.375rem;
    padding-right: 0.75rem;
  }

  &--primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $light-purple;
      color: $primary;
    }
  }

  &--secondary {
    background-color: transparent;
    border: thin solid $primary;
    color: $primary;

    &:hover {
      background-color: $light-purple;
      color: $primary;
    }
  }

  &--light {
    background-color: $secondary;
    color: $primary;
  }

  &--disabled {
    background-color: $white;
    border: thin solid $text-grey;
    color: $text-grey;
  }

  &--alertLight {
    background-color: #ffd6d3;
    color: $alert-red;
  }
}

// ICON BUTTONS
.btnRemove {
  background: url("/assets/icons/remove.svg") center no-repeat;
  background-size: contain;

  &--grey {
    background: url("/assets/icons/remove-grey.svg") center no-repeat;
  }
}

.btnAttach {
  background: url("/assets/icons/attach.svg") center no-repeat;
  background-size: contain;
}

.btnOverflow {
  background: url("/assets/icons/overflow-menu.svg") center no-repeat;
  background-size: contain;
}

.btnCollapse {
  background-size: contain;

  &--circled {
    background: url("/assets/icons/collapse-circled.svg") center no-repeat;
  }
}

.btnEdit {
  background: url("/assets/icons/edit.svg") center no-repeat;
  background-size: contain;
}

.btnSend {
  background: url("/assets/icons/send.svg") center no-repeat;
  background-size: contain;
}

.btnClose {
  &--circled {
    background: url("/assets/icons/close-red-circled.svg") center no-repeat;
    background-size: contain;
  }
}

.btnCheck {
  &--circled {
    background: url("/assets/icons/check-circled-primary.svg") center no-repeat;
    background-size: contain;
  }
}

// NAVS
.navBack {
  align-items: center;
  color: $primary;
  display: flex;
  font-size: $text14;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  text-decoration: none;
}

// SEPARATOR
.separator {
  align-items: center;
  color: $text-grey;
  display: flex;
  font-size: $text14;
  gap: 1rem;
  height: 1.5rem;
  margin: 1rem 0;

  &__line {
    background-color: #eaeaea;
    flex: 1;
    height: 0.0625rem;
  }
}

// CUSTOM SWITCH
.mooncheckSwitch {
  &.form-switch {
    .form-check-input {
      --bs-form-switch-bg: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
      background-color: #d0cfff;

      &:checked {
        background-color: $primary;
      }
    }

    .form-check-label {
      color: $primary;
      font-size: $text14;
      font-weight: 500;
    }
  }
}

// /** Main Container */
// .main-container {
// }
