//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
$primary: #6c85f7;
$primary-active: #6c85f7bd;
$primary-light: #f6f7fb;
$primary-light-dark: #a743f6;
$primary-inverse: $white;

// Success
$success: #46c1e8;
$success-active: #46c1e889;
$success-light: #fbfbff;
$success-light-dark: #41434b;
$success-inverse: $white;

// Info
$info: #9fa2a7;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #41434b;
$info-inverse: $white;

// Danger
$danger: #f01456;
$danger-active: #f0145689;
$danger-light: #d171f2;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #f0a410;
$warning-active: #d171f2;
$warning-light: #f6f7fb;
$warning-light-dark: #a743f6;
$warning-inverse: $white;
