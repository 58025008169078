// Color variables
$alert-red: #f01456;
$black: #000;
$green: #2cc056;
$light-green: #bef0cc;
$light-orange: #ffdfaf;
$light-purple: #c2c3ff;
$light-red: #ffe2ee;
$orange: #ff9900;
$primary-alt: #885fff;
$primary: #4b4efc;
$secondary: #f1f0ff;
$white: #fff;

// Text color variables
$text-dark: #41434b;
$text-grey: #9fa2a7;
$text-neutral300: #d1d5db;
$text-neutral500: #6b7280;
$text-neutral600: #4b5563;
$text-neutral800: #1f2937;

// Font size variables
$text10: 0.625rem;
$text12: 0.75rem;
$text14: 0.875rem;
$text16: 1rem;
$text18: 1.125rem;
$text20: 1.25rem;
$text24: 1.5rem;
$text28: 1.75rem;
$text48: 3rem;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-xl: 1200px;
